export const AC_URL = {
  BOS_EN: '/en/billofsale/kijiji',
  BOS_FR: '/fr/billofsale/kijiji',
  ICO_SCRIPT_ID: 'autocanada-ico-js',
  ICO_SCRIPT_URL: '/scripts/ac-ico-sdk-v1.min.js',
}

export const AUTOCAN_CTA_IDS = {
  BOS_LANDING_PAGE_CVC: 'web-bos-landing-page-car-value-calculator',
  BOS_LANDING_PAGE_SMC: 'web-bos-landing-page-sell-my-car',
  ICO_LANDING_PAGE_CVC: 'web-ico-landing-page-car-value-calculator',
  ICO_LANDING_PAGE_SMC: 'web-ico-landing-page-sell-my-car',
  VALUE_ESTIMATE_LANDING_PAGE_CVC: 'web-value-estimate-landing-page-car-value-calculator',
  VALUE_ESTIMATE_SRP: 'web-value-estimate-srp',
  VALUE_ESTIMATE_VIP: 'web-value-estimate-vip',
  WARRANTY_VIP_WITH_QUOTE: 'web-warranty-vip-with-quote',
  WARRANTY_VIP: 'web-warranty-vip',
}

export const ICO_UTM_PARAMS = {
  UTM_CAMPAIGN_ICO_BOS: 'ico-billofsale',
  UTM_CAMPAIGN_ICO_CTA: 'ico-cta',
  UTM_CAMPAIGN_ICO: 'ico',
  UTM_CONTENT_ESTIMATE_LANDING: 'estimate-landing',
  UTM_CONTENT_ESTIMATE: 'estimate-cta',
  UTM_CONTENT_FORM: 'form',
  UTM_CONTENT_ICO_LANDING: 'ico-landing',
  UTM_CONTENT_WARRANTY_WITH_QUOTE: 'with-quote-cta',
  UTM_CONTENT_WARRANTY: 'default-cta',
  UTM_MEDIUM_APP: 'app',
  UTM_MEDIUM_MWEB: 'mweb',
  UTM_MEDIUM_WEB: 'web',
  UTM_SOURCE: 'kijiji',
}

//When adding new page types, ensure you choose a key that corresponds with one of our PAGE_TYPE values
export const ICO_UTM_PARAMS_PAGE_SPECIFIC: Record<string, Record<string, string>> = {
  CAR_VALUE_CALCULATOR: {
    UTM_CAMPAIGN: 'car-value-calculator',
  },
  SELL_MY_CAR: {
    UTM_CAMPAIGN: 'sell-my-car',
  },
  SRP: {
    UTM_CAMPAIGN: 'srp',
  },
  VIP: {
    UTM_CAMPAIGN: 'vip',
  },
}

export type ICOPageType = keyof typeof ICO_UTM_PARAMS_PAGE_SPECIFIC
